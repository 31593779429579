
body
  font-family: var(--font-primary), sans-serif
  font-weight: normal
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: var(--color-title-light)

.body--clipping
  overflow: hidden

.img--responsive
  max-width: 100%
  height: auto

.App
  display: flex

.swal2-confirm
  border-radius: 45px !important
