.Splashscreen
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  background-color: #fff
  z-index: 110
  display: flex
  align-items: center
  justify-content: center
  // transition-property: all
  // transition-delay: 0
  // transition-duration: 1000ms
  transition: all 700ms cubic-bezier(.63,.02,.34,1.01), background-color 150ms ease

  &--is-done-true
    opacity: 0
    pointer-events: none
    background-color: transparent
    width: 100px
    height: 98px

    .Splashscreen__container
      width: 100%

.Splashscreen__container
  width: 45%
  transition-property: all
  transition-delay: 300ms
  transition-duration: 150ms

@media screen and (max-width: 1023px)
  .Splashscreen
    &--is-done-true
      width: 111px
      height: 84px
      left: 50%
      margin-left: -55px
