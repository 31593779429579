.Logo
  display: inline-block
  padding: 0
  margin: 0
  width: 130px
  height: 98px
  background: url('./assets/logo.png')
  background-repeat: no-repeat
  background-size: contain
  background-position: center center
  font-size: 0
  transition: all 200ms ease

  &--small
    width: 115px
    height: 77px

  &--nav
    display: none

  a
    display: block
    width: 100%
    height: 100%

@media screen and (max-width: 1439px)
  .Logo
    width: 120px
    height: 82px

@media screen and (max-width: 1023px)
  .Logo
    width: 111px
    height: 84px

    &--nav
      display: inline-block
      z-index: 1
      left: 0
      top: 0
      transform: translate(40px, -280px)
      width: 120px
      height: 85px

@media screen and (max-width: 480px)
  .Logo
    width: 81px
    height: 55px

    &--nav
      transform: translate(30px, -250px)


@media screen and (max-width: 320px)
  .Logo
    width: 70px
    height: 55px


@media screen and (max-height: 414px)
  .Logo
    &--nav
      transform: none
      top: 7%
      right: 5%
      left: auto
      position: absolute


@media screen and (max-height: 414px) and (max-width: 320px)
  .Logo
    &--nav
      transform: translate(30px, -150px)
      right: auto
      top: auto
      position: static
