.input-search-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.menuOptionsCities {
  list-style: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  height: 21vh;
}

.listItems {
  border: 0.0625rem solid rgba(205, 205, 205, 0.3);
  height: auto;
  list-style-type: none;
  display: flex;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.listItems:hover {
  background-color: rgba(255, 16, 16, 0.3);
  font-weight: bold;
}

.storeName {
  margin: 0;
  padding: 0;
}

.storeAddress {
  font-size: 12px;
  color: gray;
  margin: 0;
  padding: 0;
}

.input-typeahead {
  border: 0.0625rem solid #cdcdcd;
  height: 50px;
  width: auto;
}

.input-typeahead::placeholder {
  padding: 0 1.25rem;
}

.input-typeahead[type='text'] {
  padding: 0 2.1875rem;
  background-image: url('./assets/icon-search.svg');
  background-size: 1.25rem;
  background-position: 0.625rem 0.9375rem;
  background-repeat: no-repeat;
}
.input-typeahead:focus {
  outline: none !important;
  /* border: 0.0625rem solid red; */
}

.optionsList {
  background-color: white;
  width: auto;
  min-height: 4vh;
  border: 0.0625rem solid red;
  border-top: 0;
}
