\:root
  --font-primary: 'Signika'
  --font-secondary: 'Intro'

  --color-primary: #ED1D24

  --color-background-dark: #222222
  --color-title-dark: #ffffff
  --color-label-dark: #EAEAEA
  --color-hover-dark: #ED1D24
  --color-button-dark: #000

  --color-background-light: #FFFFFF
  --color-title-light: #222222
  --color-label-light: #636363
  --color-hover-light: #222222

  --color-gray-light: #444444

  --largest-screen: 1679px
  --larger-screen: 1439px
  --large-screen: 1279px
  --medium-screen: 1023px
  --small-screen: 768px
  --smaller-screen: 480px

  --smooth: cubic-bezier(1,.01,.05,1)
  
  --tabs-height: 70px

@media screen and (max-width: 1279px)
  \:root
      --tabs-height: 60px
