@font-face {
    font-family: 'Signika';
    src: url('Signika-Semibold.eot');
    src: url('Signika-Semibold.eot?#iefix') format('embedded-opentype'),
        url('Signika-Semibold.woff2') format('woff2'),
        url('Signika-Semibold.woff') format('woff'),
        url('Signika-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Signika';
    src: url('Signika-Bold.eot');
    src: url('Signika-Bold.eot?#iefix') format('embedded-opentype'),
        url('Signika-Bold.woff2') format('woff2'),
        url('Signika-Bold.woff') format('woff'),
        url('Signika-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Signika';
    src: url('Signika-Light.eot');
    src: url('Signika-Light.eot?#iefix') format('embedded-opentype'),
        url('Signika-Light.woff2') format('woff2'),
        url('Signika-Light.woff') format('woff'),
        url('Signika-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Signika';
    src: url('Signika-Regular.eot');
    src: url('Signika-Regular.eot?#iefix') format('embedded-opentype'),
        url('Signika-Regular.woff2') format('woff2'),
        url('Signika-Regular.woff') format('woff'),
        url('Signika-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Intro';
    src: url('Intro.eot');
    src: url('Intro.eot?#iefix') format('embedded-opentype'),
        url('Intro.woff2') format('woff2'),
        url('Intro.woff') format('woff'),
        url('Intro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

